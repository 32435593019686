import { CartState, RestaurantState, AuthState, SuitesState } from '@/store/types';
import { DateTime } from 'luxon';
import i18n from '@/i18n';

/**
 * Creates luxon DateTime object from order information to use for formatting
 *
 * @param {CheckoutPickupInfo} pickupInformation
 * @return {DateTime}
 */
function getDateTimeFromOrder(pickupInformation: CheckoutPickupInfo): DateTime {
	let returnDate = DateTime.fromISO(pickupInformation.dueByDate!).setLocale(i18n.locale);
	if(pickupInformation!.dueByTime) {
		const timeAsDate = DateTime.fromFormat('1/1/2020 ' + pickupInformation!.dueByTime, i18n.t('checkout.review.user_details.local_date_format'));
		returnDate = returnDate.set({ hour: timeAsDate.hour, minute: timeAsDate.minute });
	}
	if (pickupInformation && !pickupInformation.scheduled && pickupInformation.prepTime){
		let hour, minute;
		if (pickupInformation.prepTime > 60 ) {
			 minute = pickupInformation.prepTime % 60;
			 hour = Math.floor(pickupInformation.prepTime / 60);
		}
		else {
			 minute = pickupInformation.prepTime;
			 hour = 0;
		}
		returnDate = returnDate.plus({ hours: hour, minutes: minute });
	}

	return returnDate;
}

/**
 * Format date for user display
 *
 * @param {CheckoutPickupInfo} pickupInformation
 * @return {string}
 */
export function formatDate(pickupInformation: CheckoutPickupInfo): string {
	return getDateTimeFromOrder(pickupInformation).toFormat(i18n.t('checkout.confirmation.date_format'));
}

/**
 * Format time for user display
 *
 * @param {CheckoutPickupInfo} pickupInformation
 * @return {string}
 */
export function formatTime(pickupInformation: CheckoutPickupInfo): string {
	return getDateTimeFromOrder(pickupInformation).toFormat(i18n.t('checkout.confirmation.time_format'));
}

/**
 * Format date and time for user display
 *
 * @param {CheckoutPickupInfo} pickupInformation
 * @return {string}
 */
export function formatTakeoutDateTime(pickupInformation: CheckoutPickupInfo): string {
	return getDateTimeFromOrder(pickupInformation).toFormat(pickupInformation.dueByTime ? i18n.t('checkout.confirmation.date_format_with_time') : i18n.t('checkout.confirmation.date_format'));
}

/**
 * Format date and time for user display
 *
 * @param {string} date
 * @return {string}
 */
export function formatDateTime(date: string): string {
	return DateTime.fromISO(date).toFormat(i18n.t('checkout.confirmation.date_format_with_time'));
}

/**
 * Set the temp option
 *
 * @param {OptionGroup} optionGroup - the group option
 * @param {Option} option - the value of the option
 * @return {void}
 */
export function setTemporaryOption(optionGroup: OptionGroup, option: Option): OrderOptionGroup {
	return {
		id: optionGroup.id,
		name: optionGroup.name,
		localization: optionGroup.localization,
		optionGroupSku: optionGroup.sku ? optionGroup.sku : null,
		optionGroupType: optionGroup.type,
		requiredOption: optionGroup.required,
		multipleOption: optionGroup.allow_multiple_selection,
		order: optionGroup.order,
		memo: false,
		values: [
			formatTemporarySelectedOrderOption(option)
		]
	};
}

/**
 * Format the order item to remove unnecessary fields
 *
 * @param {MenuItem} item
 * @return {OrderItem}
 */
export function formatTemporarySelectedOrderItem(item: MenuItem): any {
	return {
		id: item.id,
		menu_group_id: item.menu_group_id,
		menu_id: item.menu_id,
		section_id: item.section_id,
		parent: item.parent,
		name: item.name,
		localization: item.localization,
		slug: item.slug,
		sku: item.sku ? item.sku : null,
		category: item.category ? item.category : null,
		price: item.price,
		memberPrice: item.memberPrice,
		promos: item.promos,
		alcoholic_beverage: item.alcoholic_beverage,
		tax_rate: item.tax_rate ? item.tax_rate : null,
		tax_rebate_eligibility: item.tax_rebate_eligibility ? item.tax_rebate_eligibility : 'eligible',
		quantity: 1,
		orderOptions: []
	};
}

/**
 * Format the order item options to remove unnecessary fields
 *
 * @param {MenuItem} item
 * @return {OrderItem}
 */
export function formatTemporarySelectedOrderOption(option: Option): any {
	return {
		id: option.id,
		name: option.name,
		localization: option.localization,
		calories: option.calories,
		product_id: option.product_id ? option.product_id : null,
		sku: option.sku ? option.sku : null,
		category: option.category ? option.category : null,
		tax_rate: option.tax_rate ? option.tax_rate : null,
		price: option.price,
		memberPrice: option.memberPrice,
		promos: option.promos,
		roll_up: option.roll_up,
		order: option.order,
		quantity: typeof option.quantity === 'number' ? option.quantity : 1,
		default_selection: option.default_selection
	}
}

/**
 * Format the order options for comparision to increase quantity or not. We are formatting time
 * to compare them with JSON.stringify. The reason we are doing this is because we have multiple
 * identifiers that may or may not change in the future, this is the easiest way to future proof
 * any changes.
 *
 * @param {OrderOptionGroup[] | null | undefined} orderOptions
 * @return {OrderOptionGroup}
 */
export function formatOrderOptionsToCompare(orderOptions: OrderOptionGroup[] | null | undefined): any {
	if(orderOptions && orderOptions.length) {

		// Order option groups
		const tempOrderOptionGroups = orderOptions!.map((orderOptionGroup: OrderOptionGroup) => {

			// Options
			const tempOptions = orderOptionGroup.values!.map((value: OrderOption) => {
				let tempSubOptionGroups: OrderOptionGroup[] | undefined = value.options;

				// Sub options
				tempSubOptionGroups = formatOrderOptionsToCompare(tempSubOptionGroups);

				// Return options
				return {
					id: value.id,
					sku: value.sku,
					name: value.name,
					localization: value.localization,
					options: tempSubOptionGroups,
					quantity: value.quantity
				};
			});

			// Return order option group
			return {
				id: orderOptionGroup.id,
				optionGroupSku: orderOptionGroup.optionGroupSku,
				name: orderOptionGroup.name,
				localization: orderOptionGroup.localization,
				memo: orderOptionGroup.memo,
				values: tempOptions
			};
		});
		return tempOrderOptionGroups;
	}
	return undefined;
}

/**
 * Format the order before submitting to the backend
 *
 * @param {CartState} import
 * @return {void}
 */
export function formatOrder(state: CartState, restaurantState: RestaurantState, authState: AuthState, suitesState: SuitesState): any {
	return {
		config: {
			restaurantId: restaurantState.restaurant.app8_restaurant,
			tableNum: state.config.tableNum,
			userId: authState.user.id ? authState.user.id : null,
			tableInfo: state.config.tableInfo,
			memberId: state.checkout.memberInfo && state.checkout.memberInfo.id ? state.checkout.memberInfo.id : null,
			token: authState.user.token ? authState.user.token : null,
		},
		items: state.items,
		costs: state.costs,
		checkout: {
			pickup: {
				delivery: state.checkout.pickup && state.checkout.pickup.delivery ? state.checkout.pickup.delivery : false,
				notes: state.checkout.pickup && state.checkout.pickup.notes ? state.checkout.pickup.notes : null
			},
			delivery: (state.checkout.pickup && state.checkout.pickup.delivery) && (state.checkout.delivery && state.checkout.delivery.type) ? state.checkout.delivery : null,
			no_takeout_timeslot: restaurantState.restaurant.noTakeoutTimeslot,
			questions: state.checkout.questions?.length ? state.checkout.questions : null,
			contact: {
				full_name: state.checkout.contact.full_name,
				email: state.checkout.contact.email,
				phone_number: state.checkout.contact.phone_number
			},
			loyaltyProgram: state.checkout.loyaltyProgram,
			card: state.checkout.card?.number ? state.checkout.card : null,
			invoice: state.checkout.invoice,
			costCenter: state.checkout.costCenter,
			purchaseOrder: state.checkout.purchaseOrder,
			applePayPaymentToken: state.checkout.applePayPaymentToken,
			applePayPaymentDetails: state.checkout.applePayPaymentDetails,
			googlePayPaymentPayload: state.checkout.googlePayPaymentPayload,
			memberInfo: state.checkout.memberInfo && state.checkout.memberInfo.id
				? { id: state.checkout.memberInfo.id, identifier: state.checkout.memberInfo.identifier, promosApplied: state.checkout.memberInfo.promosApplied ? Array.from(state.checkout.memberInfo.promosApplied) : null, promos: state.checkout.memberInfo.promos }
				: null
		},
		chosenCreditCard: {
			paymentCardId: state.chosenCreditCard ? state.chosenCreditCard.paymentCardId : null
		},
		suites: (suitesState.preOrdering || suitesState.eventDayOrdering) && suitesState.minimalEventInfo ? {
			suitesLocationId: suitesState.suitesLocationId,
			eventDayOrder: suitesState.eventDayOrdering,
			eventDayPaymentPreference: suitesState.minimalEventInfo.eventSuite.event_day_payment_preference,
			eventSuiteId: suitesState.minimalEventInfo.eventSuite.id,
			eventSuiteName: suitesState.minimalEventInfo.eventSuite.suite.name,
			// this will become an array of emails once we have additional_contacts implemented
			eventContactInfo: [authState.user.email],
			eventStartDate: suitesState.minimalEventInfo.event.start_date,
			eventLockPeriod: suitesState.minimalEventInfo.event.order_lock_period,
			eventId: suitesState.minimalEventInfo?.event.id,
			eventName: suitesState.minimalEventInfo.event.title,
			suiteOperatorEmail: authState.suiteOperator?.email ? authState.suiteOperator.email : undefined,
			eventManagerInfo: suitesState.userEventSuite && suitesState.userEventSuite.event && suitesState.userEventSuite.event.manager_info ? suitesState.userEventSuite.event.manager_info : null,
			orderId: suitesState.suiteCateringOrder ? suitesState.suiteCateringOrder.id : null,
			orderDeliveryTime: state.checkout.suitesInfo && state.checkout.suitesInfo.deliveryTime ? state.checkout.suitesInfo.deliveryTime : null,
			orderNotes: state.checkout.suitesInfo && state.checkout.suitesInfo.notes ? state.checkout.suitesInfo.notes : null,
			suiteOwner: {
				id: suitesState.minimalEventInfo.eventSuite.suite_owner.id,
				email: authState.user ? authState.user.email : null,
				companyName: suitesState.minimalEventInfo.eventSuite.suite_owner.company_name,
			},
			tabLimit: state.checkout.suitesInfo?.tabLimit ? state.checkout.suitesInfo.tabLimit : null
		} : undefined,
		date: DateTime.local().toISO()
	};
}

/**
 * Format the order item from the event suite order
 *
 * @param {OrderItem} tempOrderItem
 * @param {MenuItem} menuItem
 * @return {OrderItem}
 */
export function formatOrderItemFromEventSuiteOrder(tempOrderItem: OrderItem, menuItem: MenuItem): OrderItem {
	return {
		id: menuItem.id,
		menu_group_id: menuItem.menu_group_id,
		menu_id: menuItem.menu_id,
		section_id: menuItem.section_id,
		alcoholic_beverage: menuItem.alcoholic_beverage,
		category: menuItem.category,
		name: menuItem.name,
		localization: menuItem.localization,
		orderId: tempOrderItem.orderId,
		orderPrice: tempOrderItem.orderPrice,
		price: menuItem.memberPrice ? menuItem.memberPrice : menuItem.price,
		promos: menuItem.promos,
		quantity: tempOrderItem.quantity,
		sku: menuItem.sku,
		slug: menuItem.slug,
		tax_rate: menuItem.tax_rate,
		tax_rebate_eligibility: menuItem.tax_rebate_eligibility
	} as unknown as OrderItem;
}

/**
 * Format the order options of the item from the event suite order
 *
 * @param {OrderItem} tempOrderItem
 * @param {MenuItem} menuItem
 * @param {OrderOption} tempOrderOption
 * @param {OptionGroup | undefined} optionGroup
 * @return {OrderOption}
 */
export function formatOrderOptionFromEventSuiteOrder(tempOrderItem: OrderItem, menuItem: MenuItem, tempOrderOption: OrderOption, optionGroup: OptionGroup | undefined): OrderOption {
	let orderOption: OrderOptionGroup = {} as OrderOptionGroup;

	// Option group
	if(optionGroup) {
		orderOption = {
			id: optionGroup.id,
			name: optionGroup.name,
			localization: optionGroup.localization,
			optionGroupSku: optionGroup.sku as string,
			optionGroupType: optionGroup.type,
			requiredOption: optionGroup.required,
			multipleOption: optionGroup.allow_multiple_selection,
			order: optionGroup.order,
			memo: tempOrderOption.memo,
		}

		// Options
		if (tempOrderOption.values) {
			orderOption.values = tempOrderOption.values.map((tempOrderOptionValue: Option) => {
				let orderOptionValue = {} as OrderOption;
				const option = optionGroup.values && optionGroup.values.find((option: Option) => option.id === tempOrderOptionValue.id);
				if(option) {
					orderOptionValue = {
						id: option.id,
						name: option.name,
						localization: option.localization,
						calories: option.calories,
						product_id: option.product_id,
						sku: option.sku,
						category: option.category,
						tax_rate: option.tax_rate,
						price: option.memberPrice ? option.memberPrice : option.price,
						promos: option.promos,
						roll_up: option.roll_up,
						order: option.order,
						quantity: typeof tempOrderOptionValue.quantity === 'number' ? tempOrderOptionValue.quantity : 1,
						default_selection: option.default_selection
					}

					// Recursivity, if there are any sub options
					if(tempOrderOptionValue.options) {
						orderOptionValue.options = tempOrderOptionValue.options.map((subTempOrderOption: OrderOption) => {
							const subOptionGroup = option.options && option.options.find((tempSubOptionGroup: OptionGroup) => tempSubOptionGroup.id === subTempOrderOption.id);
							if(subOptionGroup) {
								return formatOrderOptionFromEventSuiteOrder(tempOrderItem, menuItem, subTempOrderOption, subOptionGroup);
							}
							else {
								throw new Error('Sub option group not found');
							}
						}) as OrderOptionGroup[];
					}

					return orderOptionValue;
				}
				else {
					throw new Error('Option not found');
				}
			});
		}
	}
	// Special instructions
	else {
		orderOption = {
			memo: true,
			name: tempOrderOption.name,
			localization: tempOrderOption.localization,
			values: [
				{
					name: tempOrderOption.values[0].name,
					localization: tempOrderOption.values[0].localization,
					price: tempOrderOption.values[0].price,
					roll_up: tempOrderOption.values[0].roll_up,
					quantity: tempOrderOption.values[0].quantity
				}
			]
		} as any;
	}

	return orderOption;
}

/**
 * Remove any non-digital characters from inputs
 *
 * @param {string} value - input value
 * @return {void}
 */
function removeNonDigitalCharacters(value: string): string {
	return value.replace(/\D/g,'');
}

/**
 * Format CVD to respect the format with no digital characters
 *
 * @param {string} cvd - cvd code
 * @return {void}
 */
export function formatCVD(cvd: string): string {
	return removeNonDigitalCharacters(cvd);
}

/**
 * Format the credit card numbers into 4 digits separated by dashes
 *
 * @param {string} cardNumber - cvd code
 * @return {string}
 */
export function formatCCNumber(cardNumber: string): string {
	let output = removeNonDigitalCharacters(cardNumber);
	if (output) {
		output = output.match(/.{1,4}/g)!.join('-');
	}
	return output
}

/**
 * Format the expiry date with a slash between month and year
 * @param {string} date - expiry date
 * @return {string}
 */
export function formatExpiryDate(date: string): string {
	let output = removeNonDigitalCharacters(date);
	if (output) {
		output = output.match(/.{1,2}/g)!.join('/');
	}
	return output;
}

/**
 * Format the credit card in order to save it
 * @param {CheckoutCardInfo} checkoutCard
 * @return {PaymentOption}
 */
export function formatCheckoutCardBeforeSave(checkoutCard: CheckoutCardInfo): PaymentOption {
	return {
		ccNumber: checkoutCard.number,
		expiryDate: checkoutCard.expiry_date,
		cvs: checkoutCard.cvd,
		postalCode: checkoutCard.postal_code,
		isDefault: true
	}
}

/**
* Format the phone number - remove non-digital characters and add dashes to the respective positions.
* Remove the +1 area code if autofill.
* TODO: This is only checking for autofills with +1 area codes, not the best in the long term
*
* @param {string} phoneNumber
* @param {boolean} mayBeAutofill - is the input potentially from autofill
* @return {string}
*/
export function formatPhoneNumber(phoneNumber: string, mayBeAutofill: boolean = false): string {
	let temp = phoneNumber.replace(/\D/g, '');
	if (mayBeAutofill && temp.length === 11 && temp[0] === '1') {
		temp = temp.substring(1);
	}
	if (temp) {
		return temp.match(/\d{3}(?=\d{2,3})|\d+/g)?.join('-') || '';
	}
	return '';
}